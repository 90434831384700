import React, { useState } from 'react';
import { Table,Input } from 'antd';
import { useQuery } from 'react-apollo-hooks';
import DELIVER_NOTIFICATION from '../../api/DeliveryNotification.graphql';
import './index.scss'
import fuzzysort from 'fuzzysort';

const { Search } = Input;

const columns = [
	// { title: 'POZYCJA', dataIndex: 'shipOrderDetailId' },
	{ title: 'NR ZAMÓWIENIA', dataIndex: 'shipOrderNumber' },
	// { title: 'ID ZAMÓWIENIA', dataIndex: 'shipOrderId' },
	{ title: 'KOD', dataIndex: 'articleCode' },
	{ title: 'NAZWA', dataIndex: 'articleName' },
	{ title: 'ILOŚĆ', dataIndex: 'articleQty' }
	// { title: 'KOMENTARZ', dataIndex: 'shipOrderComment' },
];
const config={rowKey: (record: any) => record.shipOrderId,
		bordered: false,
		expandRowByClick: true,
		// size: "small",
		// rowSelection: null,
		hasData: false,

		scroll: { y: false },
		expandIconAsCell: false,
		expandIcon: () => <span />,
		// expandedRowRender: (record: any) => (
		// 	<div>
		// 		<span className="table--comment">Komentarz do zamówienia:</span>
		// 		<br /> {record.shipOrderComment}
		// 	</div>
		// )
	}
		const options = {
  limit: 100, // don't return more results than you need!
  allowTypo: true, // if you don't care about allowing typos
  threshold: -10000, // don't return bad results
}
export default () => {
	const [ searchString, setSearchString ] = useState();
	const { data, loading, error } = useQuery(DELIVER_NOTIFICATION, {
		variables: { guid: window.location.pathname.substring(1) }
	});
	let search;
	let tableData:Array<any>=[];

	if(!!data && !loading &&!error)
	{
		search = fuzzysort.go(searchString, data.deliveryNotification.details, {keys:['shipOrderNumber','articleCode','articleName'], ...options});
		if(!!searchString && searchString.length>0)
		{tableData=search.map(({obj}) => obj ? obj:null);}else{tableData=data.deliveryNotification.details}
	}

	return (
		<>

		<div className="main-page--search">
				<Search
					placeholder="Wyszukaj dostawę..."
					onSearch={value=>setSearchString(value)}
					onChange={e=>setSearchString(e.target.value)}
					enterButton
				/>
			</div>
		<Table
			{...config}
			loading={loading}
			columns={columns}
			pagination={false}
			size={'small'}
			dataSource={!loading && !error && data ? tableData : undefined}
		/>
		</>
	);
};
