const gql = require('graphql-tag');

export default gql`
	query DNH($guid: String!) {
		deliveryNotification(data: { deliveryNotificationsNumber: $guid }) {
			deliveryNotificationsNumber
			routeId
			routeNumber
			routeDescr
			routeComment
			unloadingSymbol
			unloadingName
			unloadingAdress
			unloadingPostalCode
			unloadingCity
			deliveryETA
			deliveryETD
		}
	}
`;
