const gql = require('graphql-tag');

export default gql`
	query DN($guid: String!) {
		deliveryNotification(data: { deliveryNotificationsNumber: $guid }) {
			deliveryNotificationsNumber
			details {
				shipOrderId
				shipOrderDetailId
				shipOrderNumber
				shipOrderComment
				articleCode
				articleName
				articleQty
			}
		}
	}
`;
