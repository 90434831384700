import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { data } from './clients';

const WrappedApp = () => (
	<ApolloHooksProvider client={data}>
		<App />
	</ApolloHooksProvider>
);

render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
