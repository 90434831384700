import React from 'react';
import './App.css';
import MainPage from './pages';

const App: React.FC = () => {
	return (
		<div className="App">
			<MainPage />
		</div>
	);
};

export default App;
