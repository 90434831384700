import ApolloClient from 'apollo-boost';
import * as config from '../utils/config';

export const data = new ApolloClient({
	uri: config.PROD ? config.REACT_APP_DATA_ENDPOINT : config.REACT_APP_DATA_ENDPOINT,
	credentials: 'include'
});
// export const auth = new ApolloClient({
// 	uri: process.env.REACT_APP_AUTH,
// 	credentials: 'include'
// });
