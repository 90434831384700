import React from 'react';
import OrdersTable from '../components/OrdersTable';
import { Icon } from 'antd';
import './index.scss';
import { useQuery } from 'react-apollo-hooks';
import DNH from '../api/DNH.graphql';
import format from 'date-fns/format';
import Spinner from '../components/Spinner/view';

const UnloadingInfo = () => {
	const { data, loading, error } = useQuery(DNH, { variables: { guid: window.location.pathname.substring(1) } });
	return (
		<div className="info-wrapper">
			{!!data &&
			!loading &&
			!error && (
				<>
				<div className="contractor">
					<span className="contractor--name">
						{data.deliveryNotification.unloadingName.toLocaleUpperCase()}
					</span>
					<span className="contractor--address">{data.deliveryNotification.unloadingAdress}</span>
					<span className="contractor--address">{`${data.deliveryNotification.unloadingPostalCode} ${data
						.deliveryNotification.unloadingCity}`}</span>
				</div>
					<div className="delivery-time">

						<span className="delivery-time--eta">
							<Icon type="clock-circle" />{' '}
							{format(new Date(data.deliveryNotification.deliveryETA), 'dd-MM-yyyy HH:mm')}
						</span><br />
						<span className="delivery-time--eta_text">
						Przewidywana data dostawy
						</span>
					</div>
					</>
			)}
			{!!loading && <Spinner />}
		</div>
	);
};

export default () => (
	<div className="main-page">
		<div className="main-page--table">
			<UnloadingInfo />
			<OrdersTable />
		</div>
	</div>
);
